// import * as turf from '@turf/turf'

export  function exportMapserverMap(mapfile,width,height,resolution,extent){

      //  console.log(extent)
        var mm2inch=0.0393700787;
        const mapserverMap=`${process.env.REACT_APP_MAPSERV_CGI}?map=${process.env.REACT_APP_MAPS_DIR}`
    
       // var extent=extent.split(',').join(' ');
        
        width=resolution*(width*mm2inch);
        height=resolution*(height*mm2inch);
   
        let map_path=mapserverMap+'/'+mapfile+'&SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap&FORMAT=image%2Fpng&CRS=EPSG%3A3857&STYLES=&WIDTH='+width+'&HEIGHT='+height+'&BBOX='+extent+'&layers=all';
        
        window.open(map_path, 'Zenith map');

}

// export function printRectangle(map){
//   let draw = new Control();
//   map.addControl(draw.control,updateArea);
//   draw.control.changeMode('draw_rectangle');

//   function updateArea(e){
    
//     const data = draw.control.getAll();
//     if (data.features.length > 0) {

//       let geom=data.features[0].geometry

//       //geom=scaleGeomToFit(geom,width,height)
//       var bbox = turf.bbox(geom);
//       console.log(bbox)

//       const xymin=map.getProjectedCoord([bbox[0],bbox[1]])
//       const xymax=map.getProjectedCoord([bbox[2],bbox[3]])

//       bbox = turf.bbox(turf.toMercator(geom)).toString();

//       const width=xymax.x-xymin.x;
//       const height=-(xymax.y-xymin.y);

//       exportMapserverMap('bogdan@zenithmaps.com/current.map',width,height,96,bbox) 

//       map.removeControl(draw.control);

//     } else {
//         if (e.type !== 'draw.delete')
//         alert('Click the map to draw a polygon.');
//     }
  
//   }

// }

// export function scaleGeomToFit(geom,width,height){

//   var bbox = turf.bbox(geom);
//   let geomWidth=bbox[2]-bbox[0];
//   let geomHeight=bbox[3]-bbox[1];
//   let scaleFactor;
//   let toFitRatio

//   if(width>height){
//       toFitRatio=width/height;
//       scaleFactor=(geomHeight*toFitRatio)/geomWidth;
//   }
//   else{
//       toFitRatio=height/width;
//       scaleFactor=(geomWidth*toFitRatio)/geomHeight;
//   }

//   geom=turf.transformScale(geom,scaleFactor)

//   return geom

// }

