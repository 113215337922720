
import React from 'react'
import Style from './Style'
// import {useStore} from 'common/utils/store.js'

export default function Class({ cls , layerIndex, classIndex, saveMap}){

    // const setMapJson = useStore((state) => state.setMapJson);
    // let mapJson={...useStore((state) => state.mapJson)}

    // const  handleChange=(name,value)=>{
    //     mapJson.layers[layerIndex].classes[classIndex][name]=value
    //     setMapJson(mapJson)
    // }

    return(
        <div className="d-flex justify-content-start">         
             {/* {
                cls.styles&&
                cls.styles.map((item, index) => (
                    <>  
                        <div className="px-3">
                                <Style style={item} styleIndex={index} classIndex={classIndex} layerIndex={layerIndex} saveMap={saveMap}></Style>
                        </div>
                    </>
                ))} */}
                {
                cls.styles ?  <div className="px-3"> <Style style={cls.styles[0]} styleIndex={0} classIndex={classIndex} layerIndex={layerIndex} saveMap={saveMap}></Style></div> : ''  
                }
             <span>{cls.name}</span>    
        </div>
    )
}

