import React, { useEffect, useRef, useCallback, useMemo } from "react";
import * as turf from '@turf/turf'


export default function MapserverLayer({map,name,refreshCount}) {

  const mapBoxLayer=useRef(null);
  const mapRef=map.current;

  const refreshLayer=useCallback(()=>{
    const canvas=mapRef.getCanvas();
      const coordinates=mapRef.getViewportCoords()

      let xy_min=turf.toMercator(turf.point(coordinates[3]))
      let xy_max=turf.toMercator(turf.point(coordinates[1]))


      const bbox=`${xy_min.geometry.coordinates[0]},${xy_min.geometry.coordinates[1]},${xy_max.geometry.coordinates[0]},${xy_max.geometry.coordinates[1]}`
      const url=`${process.env.REACT_APP_MAPSERV_CGI}?map=${process.env.REACT_APP_MAPS_DIR}/bogdan@zenithmaps.com/current.map&SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap&FORMAT=image/png&TRANSPARENT=true&LAYERS=all&CRS=EPSG:3857&STYLES=&WIDTH=${canvas.width}&HEIGHT=${canvas.height}&BBOX=${bbox}`

      mapRef.updateImageSource(name,url,coordinates)
  },[mapRef, name])
  
  const moveEndHandler = useCallback((e) => {
    
    if(mapBoxLayer.current){
      refreshLayer()
    }
    
  },[refreshLayer]);


  useMemo(()=>{

    if(mapBoxLayer.current)
      refreshLayer();

  },[refreshLayer,refreshCount])

  /////cleaning when component unmounts
  useEffect(() => {
    console.log("mapserver layer mounted")
    const canvas=mapRef.getCanvas();
    const coordinates=mapRef.getViewportCoords()

    let xy_min=turf.toMercator(turf.point(coordinates[3]))
    let xy_max=turf.toMercator(turf.point(coordinates[1]))


    const bbox=`${xy_min.geometry.coordinates[0]},${xy_min.geometry.coordinates[1]},${xy_max.geometry.coordinates[0]},${xy_max.geometry.coordinates[1]}`
    mapBoxLayer.current=mapRef.addImageSource({name:name,
                          url: `${process.env.REACT_APP_MAPSERV_CGI}?map=${process.env.REACT_APP_MAPS_DIR}/bogdan@zenithmaps.com/current.map&SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap&FORMAT=image%2Fpng&TRANSPARENT=true&LAYERS=all&CRS=EPSG%3A3857&STYLES=&WIDTH=${canvas.width}&HEIGHT=${canvas.height}&BBOX=${bbox}`,
                          coordinates:mapRef.getViewportCoords()
                          })
  
    mapBoxLayer.current=mapRef.addImageLayer({name:name,
                         sourceName:name
                       })
    mapRef.setMoveEndHandler(moveEndHandler);

    return () => {
      console.log("mapserver layer unmounted")
      mapRef.removeMoveEndHandler(moveEndHandler);
      mapRef.removeLayer(name)
      mapRef.removeSource(name)
      mapBoxLayer.current=null;
    };
  },[]);// eslint-disable-line react-hooks/exhaustive-deps


  return <React.Fragment></React.Fragment>;
}
