import React, { useEffect } from 'react';
import { StyleModal } from './StyleModal';
import {Table} from '@zenithmaps/zenith_client'

const LayerModalTable = ( {modaleState, setModaleState , addNewLayer} ) => {

    const layersModel=[
        {
            "field":"mapfile",
            "title":"Mapfile"
        },
        {
            "field":"layer",
            "title":"Layer"
        }
    ]

    const action_import=(json)=>{
        addNewLayer(json)
     }

 useEffect(()=>{
    
        // setIsLoading(true)
        // axios.get(process.env.REACT_APP_MAPSERVER+'/api/layers/').then(response => {
        //                                                 setIsLoading(false)

        //                                             })		

 },[])
    const actions=[
        {
            name: "view",
            onClickFunc: action_import,
            param: "json",
            buttonIcon: <i class="fa-solid fa-file-import"></i>
        }
    ]



    return (
    <>  
        <StyleModal setModaleState={setModaleState} className="h-75" modaleState={modaleState} >
            <div className="container pt-5 " style={{height: 500+"px"}}>
                <div className="mx-1">
                    <p className="text-center green-text h5">Mapeditor</p>
                </div>
                <div className="col-md-12 text-left position-relative">
                    <Table model={layersModel} tableCount={1} table_name="mapeditor"  table_url={process.env.REACT_APP_MAPSERVER+"/api/layers"} actions={actions}  paging search/>  
                </div>  
            </div> 
        </StyleModal>

    </>
  );
};

export default LayerModalTable