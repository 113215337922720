import React, { useEffect, useRef } from "react";
import {GetObject} from '@zenithmaps/zenith_client'
import {MapWrapper} from 'mapbox/MapWrapper'

export default function Map({ map, setMapLoaded }) {
  const mapContainer = useRef(null);
  const mapboxToken = process.env.REACT_APP_MAPBOX_TOKEN;

  const lng=GetObject("lng") ? GetObject("lng") : 25.7000;
  const lat=GetObject("lat") ? GetObject("lat") : 45.8000;
  const zoom=GetObject("zoom") ? GetObject("zoom") : 6.70;

  function mapLoaded(){
    map.current.zoomTo(lng, lat, zoom)
    setMapLoaded(true)
  }

  useEffect(() => {
  //  if(!map.current)
    map.current=new MapWrapper(mapContainer.current,mapboxToken,mapLoaded);

    return () => {
      if(map.current)
        map.current.removeMap()
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  return (
    <>
      <div ref={mapContainer} className='map-container'></div>
    </>
  );
}
