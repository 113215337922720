import React, {useState} from 'react'
import {Table} from '@zenithmaps/zenith_client'
import HartiModel from './HartiModel.json'
import HartiForm from './HartiForm'

export default function HartiPage({map,mapLoaded}) {

    const [recordId,setRecordId]=useState(-1)
    const [formMethod, setFormMethod]=useState()
    const [tableCount, setTableCount]=useState(0)

    const action_view=(new_Id)=>{
        setFormMethod("view")
        setRecordId(new_Id)
    }
    const action_edit=function(new_Id){
        setFormMethod("edit")
        setRecordId(new_Id)
    }
    function action_delete(new_Id){
        setFormMethod("delete")
        setRecordId(new_Id)
    }

    function action_create(){
        setFormMethod("create")
		setRecordId(-1)
    }
	
	function action_zoom(geom){
		map.current.zoom2geom(geom)
	}

	let block;
        block=<div className="container pt-5">
                <div className="mx-1">
                    <p className="text-center green-text h5">Harti</p>
                </div>
                <div className="col-md-12  text-left ">
                { mapLoaded ? <HartiForm  setTableCount={setTableCount} tableCount={tableCount} recordId={recordId} method={formMethod} map={map}  onSelected={action_edit}/>  : '' }
                </div> 
                <div className=" d-flex justify-content-end">
                    <button id="create_rec" type="button" className="btn btn-outline-green btn-rounded px-2 waves-effect waves-light" onClick={action_create} title="Create">
                        <i className="fas fa-pencil-alt mt-0"></i>
                    </button>
					<button id="del_rec" type="button" className="btn btn-outline-green btn-rounded px-2 waves-effect waves-light" onClick={()=>setFormMethod("delete")} title="Delete">
                        <i className="far fa-trash-alt mt-0"></i>
                    </button>
                </div> 
                <div className="col-md-12  text-left ">
                    { mapLoaded ? <Table model={HartiModel} tableCount={tableCount} table_name="harti" table_url="/harti" action_view={action_view} action_edit={action_edit} action_delete={action_delete} action_zoom={action_zoom} paging search/> : '' } 
                </div>  
            </div> 
    return block         
  }
