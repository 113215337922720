
import React, {useState} from 'react'
import Class from './Class'
import {TextAreaCtrl} from '@zenithmaps/zenith_client'
import {useStore} from 'common/utils/store.js'
import {CheckBoxCtrl} from  '@zenithmaps/zenith_client'

export default function Layer({layer,layerIndex,saveMap}){
   
    const setMapJson = useStore((state) => state.setMapJson);
    let mapJson={...useStore((state) => state.mapJson)}
    const [display,setDisplay]=useState("symbol")

    const  handleChange=(name,value)=>{
       // console.log(name+":"+value)

        if(name==="status"){
            if(value)
                mapJson.layers[layerIndex].status="ON"
            else 
                mapJson.layers[layerIndex].status="OFF"
            saveMap()
        }
        else    
            mapJson.layers[layerIndex]=JSON.parse(value)
        setMapJson(mapJson)

    }

    const removeLayer=(e)=>{
        if(layer.name==='delete'){
            mapJson.layers.splice(layerIndex,1)
            setMapJson(mapJson)
        }
    }

    let checked=layer.status==="ON" ? true : false
    return(
        <div className="">
            <div className="d-flex justify-content-between">
                <CheckBoxCtrl
                        label={layer.name}
                        name="status"
                        value="1"
                        onChange={handleChange}
                        placeholder=''
                        checked={checked}
                        className="form-check-input"
                        classLabel="form-check-label"
                    /> 
                <div class="text-end">
                    <button class="btn editor_edit px-1"><i class="far fa-eye fa-sm green-text" onClick={()=>{setDisplay('symbol')}}></i></button>
                    <button class="btn editor_edit px-1"><i class="fas fa-pencil-alt fa-sm green-text" onClick={()=>{setDisplay('edit')}}></i></button>
                    <button class="btn editor_edit px-1"><i class="far fa-trash-alt fa-sm green-text" onClick={()=>{removeLayer()}}></i></button>
                </div>
            </div>
    
            {display==="edit" ? <TextAreaCtrl  name={mapJson.layers[layerIndex]["name"]}  value={JSON.stringify(layer,null,2)} onChange={handleChange}/> : ''}
            
            {/* <MapInputCtrl label="name" name="name" type="text" value={layer.name} onChange={handleChange} className=""/>
            <button id="create_rec" type="button" class="btn  btn-rounded px-2 " title="Create" onClick={removeLayer}>
                    <i class="fa-solid fa-circle-minus text-black"></i> Delete layer
            </button>
            <MapInputCtrl label="CONNECTIONTYPE" name="CONNECTIONTYPE" type="text" value={layer.CONNECTIONTYPE} onChange={handleChange} className="ps-3"/>
            <MapInputCtrl label="DATA" name="DATA" type="text" value={layer.DATA} onChange={handleChange} className="ps-3"/>
            <MapInputCtrl label="STATUS" name="STATUS" type="text" value={layer.STATUS} onChange={handleChange} className="ps-3"/>
            <MapInputCtrl label="TYPE" name="TYPE" type="text" value={layer.TYPE} onChange={handleChange} className="ps-3"/>
            <MapInputCtrl label="OPACITY" name="OPACITY" type="text" value={layer.OPACITY} onChange={handleChange} className="ps-3"/>
            <MapInputCtrl label="CLASSITEM" name="CLASSITEM" type="text" value={layer.CLASSITEM} onChange={handleChange} className="ps-3"/>
            <MapInputCtrl label="SYMBOLSCALEDENOM" name="SYMBOLSCALEDENOM" type="text" value={layer.SYMBOLSCALEDENOM} onChange={handleChange} className="ps-3"/> */}
            {/* <div class=" d-flex justify-content-end">
                <button id="create_rec" type="button" class="btn  btn-rounded px-2 " title="Create" onClick={addNewClass}>
                    <i class="fa-solid fa-circle-plus text-black"></i>
                </button>
                <button id="del_rec" type="button" class="btn  btn-rounded px-2 " title="Delete">
                    <i class="far fa-trash-alt mt-0 text-white"></i>
                </button>
            </div> */}
            {
                layer.classes&&
                layer.classes.map((item, index) => (
                    <>  
                        <div className="ps-3">
                            <Class cls={item} classIndex={index} layerIndex={layerIndex} saveMap={saveMap}></Class>
                        </div>
                    </>
                ))}
        </div>
    )
}