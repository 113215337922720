import axios from "axios";
import { toast } from "react-toastify";

export default class AxiosInit {
  refCount = 0;
  requestInterceptor
  responseInterceptor

  constructor(jwt,resetUser){
    axios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["Accept"] = "application/json";
//    axios.defaults.baseURL = BASE_URL;

    this.requestInterceptor=axios.interceptors.request.use(async (req) => {
    
      if (req.method === 'post') {  
        req.data = {
            ...req.data,
            _t: Date.parse(new Date()) / 1000
          }
      } else if (req.method === 'get') {
        req.params = {
          _t: Date.parse(new Date()) / 1000,
          ...req.params
        }
      }
    
      return req;
    });

    this.responseInterceptor=axios.interceptors.response.use(function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
      }, function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        if(error?.response?.data?.message){
          toast.error(`${error.response.data.message}`,{position:toast.POSITION.TOP_CENTER});
        }
        return Promise.reject(error);
      })

  }

  destructor(){
    axios.interceptors.request.eject(this.requestInterceptor);
    axios.interceptors.response.eject(this.responseInterceptor);
  }
 

}



