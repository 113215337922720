import React, {useState, useEffect} from 'react';
import Select from 'react-select'
import axios from 'axios'

export function  SpeciesSelectCtrl({mapJson,setMapJson,saveMap}) {
		
	const [selectValue,setSelectValue]=useState({value:"",label:""})
	const [options,setOptions]=useState()
	
	const handleChange=(valueObj,actionObj) => {
		if(actionObj.action==='clear') {
			for (const layer of mapJson.layers) {	
				delete layer.filter;
			}
			setMapJson(mapJson)
		}
			
		if(actionObj.action==='select-option'){
			
			for (const layer of mapJson.layers) {
				if(layer.metadata?.filter==="nume_specie")	
					layer.filter="([nume_specie]='"+valueObj.label+"')"
			}
			setMapJson(mapJson)
		}
			
	};
	
useEffect(()=>{
	axios.get("http://localhost:3002/api/species").then(response => { setOptions(response.data) })
},[])
	
    return (
		<div className="row">
			<div className="col-md-10  text-left ">
				<Select onChange={handleChange}
					name="species_list"
					options={options}
					isClearable='true'	
					isSearchable='true'
				/>
	        </div> 
            <div class=" col-md-2 col-sm-2 col-lg-2">
                <button class="btn btn-sm btn-green waves-effect waves-light" id="filter" onclick={saveMap}>
                <i class="fas fa-search"></i>
                </button>
            </div> 
        </div>
    )
}

