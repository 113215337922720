import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios'
import Layer from './Layer.js';
import {MapInputCtrl} from '@zenithmaps/zenith_client'
import {useStore} from 'common/utils/store.js'
import {Spinner} from '@zenithmaps/zenith_client'
import {ButtonCtrl} from '@zenithmaps/zenith_client'
import {SpeciesSelectCtrl} from './SpeciesSelectCtrl'
import {Link} from 'react-router-dom'
import MapserverLayer from 'maplayers/MapserverLayer'
import LayersModalTable from './LayersModalTable.js'
import { exportMapserverMap } from "mapbox/exportMapserverMap";
import { Control } from 'mapbox/MapWrapper'
import * as turf from '@turf/turf'
//import DrawControl from 'react-mapbox-gl-draw';

const Mapeditor = ({map,mapLoaded}) => {

    const {id, method} = useParams();
    const dragItem = useRef();
    const dragOverItem = useRef();
    const drawCtrl=useRef();

    const [refreshCount,setRefreshCount]=useState(0)

    const setMapJson = useStore((state) => state.setMapJson);
    let mapJson={...useStore((state) => state.mapJson)}
    const [modaleState,setModaleState] = useState(false);

    useEffect(()=>{
        
        if(id>-1){
            setIsLoading(true)

            axios.get(process.env.REACT_APP_MAPSERVER+'/api/mapfiles/'+id).then(response => {
                                                            setIsLoading(false)
                                                            console.log(JSON.parse(response.data.row.json))
                                                            setMapJson(JSON.parse(response.data.row.json))
                                                            setRefreshCount(refreshCount+1)
                                                        })		
        }
        else{
            let mapJson={
                'name':'',
                'layers':[
                ]
            }
            setMapJson(mapJson)
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])



    const dragStart = (e, position) => {
        dragItem.current = position;
        console.log(e.target.innerHTML);
    };
    
    const dragEnter = (e, position) => {
        dragOverItem.current = position;
        console.log(e.target.innerHTML);
    };
    
    const drop = (e) => {
        console.log('ondrop')
        const copyListItems = mapJson.layers;
        const dragItemContent = copyListItems[dragItem.current];
        copyListItems.splice(dragItem.current, 1);
        copyListItems.splice(dragOverItem.current, 0, dragItemContent);
        console.log(dragItemContent)
        dragItem.current = null;
        dragOverItem.current = null;
        mapJson.layers=copyListItems
        setMapJson(mapJson)
        handleSubmit(e)
    };

    const  handleChange=(name,value)=>{
        mapJson[name]=value
        setMapJson(mapJson)
    }

    const addNewLayer=(newLayer)=>{
        mapJson.layers.unshift(newLayer)
        setMapJson(mapJson)
    }

    const [message,setMessage]=useState('')
	const [messageClass,setMessageClass]=useState('')
	const [isLoading,setIsLoading]=useState(false)
    let apiCall = axios.post
    let btnLabel=''

	switch(method) {
        case "view":
            apiCall = axios.get
            btnLabel="Close"
          break;
        case "edit":
            apiCall = axios.put
            btnLabel="Save"
          break;
        case "delete":
            apiCall = axios.delete
            btnLabel="Delete"
        break; 
        case "create":
            apiCall = axios.post
            btnLabel="Create"
        break; 
        default:
            apiCall = axios.post
            btnLabel="Create"
        break;
    }

    const handleSubmit = (event) => {
        event.preventDefault();  
        saveMap();
      }

      function saveMap(){

        setMessage("Loading...")
        setIsLoading(true)
        console.log(id)
        apiCall(process.env.REACT_APP_MAPSERVER+'/api/mapfiles/'+id,{'json':JSON.stringify(mapJson)})
          .then( response => {
              setMessage(response.data.message)
              setMessageClass('alert alert-success')
              setIsLoading(false)
              setRefreshCount(refreshCount+1)
          })
          .catch( error => {
              setMessageClass('alert alert-danger')
              setIsLoading(false)
              if(error.response.data.errors){
                  setMessage('Please correct the errors')
              }
              else{
                  setMessage(error.response.data.message)
              } 
          })
      }

      function printRectangle(){
        drawCtrl.current = new Control();

        map.current.addControl(drawCtrl.current.control,updateArea);

        drawCtrl.current.control.changeMode('draw_rectangle');
      }

      function updateArea(e){
          
        const data = drawCtrl.current.control.getAll();
        if (data.features.length > 0) {
    
          let geom=data.features[0].geometry

          var bbox = turf.bbox(geom);
          console.log(bbox)
    
          const xymin=map.current.getProjectedCoord([bbox[0],bbox[1]])
          const xymax=map.current.getProjectedCoord([bbox[2],bbox[3]])
    
          bbox = turf.bbox(turf.toMercator(geom)).toString();
    
          const width=xymax.x-xymin.x;
          const height=-(xymax.y-xymin.y);
    
          exportMapserverMap('bogdan@zenithmaps.com/current.map',width,height,96,bbox) 
    
          map.current.removeControl(drawCtrl.current.control,updateArea);
    
        } else {
            if (e.type !== 'draw.delete')
            alert('Click the map to draw a polygon.');
        }
      }
      
      let btn
      if(method==="view"){
          
      }
      else		
          btn=<ButtonCtrl
              type="submit"
              label={btnLabel}
              className="btn btn-primary btn-rounded px-4 text-white nav-link active z-depth-0"
              handleClick=""
            />
    return (
    <div className="px-3  mt-5">  
        <Spinner isLoading={isLoading}/>  

                                    <SpeciesSelectCtrl
                                        mapJson={mapJson}
                                        setMapJson={setMapJson}
                                        saveMap={saveMap}
                                    />  

        <form onSubmit={handleSubmit} className="py-1" >
            <div className="row ">
                <div className="col-md-12 py-1 mb-1 d-flex  justify-content-between ">   
                        {btn}
                        <button type="button"  className="btn btn-primary btn-rounded px-4 text-white nav-link active z-depth-0"  onClick={printRectangle}>
                            Print
                        </button>
                            
                        <Link to="/mapeditor" className="btn btn-primary btn-rounded px-4 text-white nav-link active z-depth-0">Close</Link>
                </div> 
                <div className="col-md-12 pb-1"> 
                    <div className={messageClass}>{message}
                    </div>
                </div> 
            </div>    
        </form>
        <MapInputCtrl label="MAP NAME" name="name" type="text" value={mapJson.name} onChange={handleChange} className=""/>
        <button id="create_rec" type="button" class="btn  btn-rounded px-2 " title="Create" onClick={()=>{setModaleState(true)}}>
            <i class="fa-solid fa-circle-plus text-black"></i>
        </button>
        <LayersModalTable addNewLayer={addNewLayer}  setModaleState={setModaleState} modaleState={modaleState}/>
        {
            mapJson.layers&&
            mapJson.layers.map((item, index) => (
                <>  
                    <div
                        onDragStart={(e) => dragStart(e, index)}
                        onDragEnter={(e) => dragEnter(e, index)}
                        onDragEnd={drop}
                        key={index}
                        draggable>
                        <Layer layer={item} layerIndex={index} saveMap={saveMap}></Layer>
                    </div>
                </>
            ))
        }
        {mapLoaded && refreshCount>0 ? <MapserverLayer map={map} name="current" refreshCount={refreshCount}/> : ''}
    </div>
  );
};

export default Mapeditor
