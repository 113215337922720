import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import DrawRectangle from 'mapbox-gl-draw-rectangle-mode';

export class MapWrapper {
    #map=null

    constructor(container,mapboxToken,setMapLoaded) {

        console.log("Map init!")
        mapboxgl.accessToken = mapboxToken;
        this.#map = new mapboxgl.Map({
            container: container,
            style: "mapbox://styles/mapbox/streets-v11",
        });

        this.#map.on("load", () => {
            setMapLoaded(true);
        });

    }

    addVectorSource({name,type,generateId}){
        this.#map.addSource(name, {
                            type: type,
                            generateId: generateId,
                            data: {
                            type: "FeatureCollection",
                            features: [{ type: "Feature", properties: {}, geometry: null }],
                            },
                        });

    }

    addImageSource({name,url,coordinates}){
        console.log("create source: "+name)
        let source=this.#map.addSource(name, {
            type: 'image',
            url: url,
            coordinates:coordinates
            });
        return source;
    }


    addImageLayer({name,sourceName}){
        console.log("create layer: "+name)
        let layer=this.#map.addLayer({
                                        id: name,
                                        type: 'raster',
                                        source: sourceName,
                                
                                    });
        return layer;
    }

    updateImageSource(name,url,coordinates){
        let source=this.#map.getSource(name)
        source.updateImage({ url:url,
                             coordinates:coordinates
                             })
    }

    addClusterSource({name,type,generateId,clusterMaxZoom,clusterRadius}){
        this.#map.addSource(name, {
            type: type,
            generateId: generateId,
            cluster: true,
            clusterMaxZoom: clusterMaxZoom, // Max zoom to cluster points on
            clusterRadius: clusterRadius, // Radius of each cluster when clustering points (defaults to 50)
            data: {
            type: "FeatureCollection",
            features: [{ type: "Feature", properties: {}, geometry: null }],
            },
        });

    }

    addVectorLayer({name,sourceName,type,visibility,paint}){
        let layer=this.#map.addLayer({
                                        id: name,
                                        type: type,
                                        source: sourceName,
                                        paint: paint,
                                        layout: {
                                            visibility: visibility,
                                    },
        });

        return layer;
    }

    addRasterTileSource({name,url,attribution}){
        this.#map.addSource(name, {
            'type': 'raster',
            'tiles': [
                url
            ],
            'tileSize': 256,
            'attribution': attribution
            });

    }

    addRasterTileLayer({name,sourceName}){
        let layer=this.#map.addLayer({
                            'id': name,
                            'type': 'raster',
                            'source': sourceName,
                            'minzoom': 0,
                            'maxzoom': 22
                        });
        return layer
    }

    addSymbolLayer({name,sourceName,layout}){

        let layer=this.#map.addLayer({
                                        id: name,
                                        type: 'symbol',
                                        source: sourceName,
                                      //  filter: ['has', 'point_count'],
                                        layout: layout
                                        });
        return layer
        
    }

    addControl(control,onDrawCallback){
        this.#map.addControl(control);
        this.#map.on('draw.create', onDrawCallback);
        this.#map.on('draw.delete', onDrawCallback);
        this.#map.on('draw.update', onDrawCallback);

    }

    removeControl(control,onDrawCallback){
        this.#map.removeControl(control)
        this.#map.off('draw.create', onDrawCallback);
        this.#map.off('draw.delete', onDrawCallback);
        this.#map.off('draw.update', onDrawCallback);
    }

    addPopup(popup){
        popup.addTo(this.#map);
    }

    getZoom(){
        return this.#map.getZoom();
    }

    setLayerVisibility(name,visible){
        this.#map.setLayoutProperty(name, "visibility", visible);
    }

    setLayerZoomRange(name,minZoom,maxZoom){
        this.#map.setLayerZoomRange(name, minZoom, maxZoom);
    }


    zoomTo(lng,lat,zoom){
        console.log(zoom)
        this.#map.flyTo({center: [lng, lat], zoom: zoom});
    }

    setLayerData(name,data){
        this.#map.getSource(name).setData(data);
    }

    setCursor(cursor){
        this.#map.getCanvas().style.cursor = cursor;
    }

    setFeatureState(name,featureId,hover){
        this.#map.setFeatureState({ source: name, id: featureId}, { hover: hover });
    }

    getViewportCoords(){
        var canvas = this.#map.getCanvas(),
        w = canvas.width,
        h = canvas.height,
        cUL = this.#map.unproject([0,0]).toArray(),
        cUR = this.#map.unproject([w,0]).toArray(),
        cLR = this.#map.unproject([w,h]).toArray(),
        cLL = this.#map.unproject([0,h]).toArray();

        var coordinates = [cUL,cUR,cLR,cLL];
        return coordinates;
    }

    getCanvas(){
        return this.#map.getCanvas()
    }

    getBounds(){
        return this.#map.getBounds();
    }

    getProjectedCoord(coordinate){
        console.log(coordinate)
        return this.#map.project(coordinate);
    }

    ///////////////EVENT HANDLERS///////////////////

    setMoveEndHandler(handler){
        this.#map.on('moveend',handler)
    }

    removeMoveEndHandler(handler){
        this.#map.off('moveend',handler)
    }

    setZoomHandler(handler){
        this.#map.on('zoom',handler)
    }

    removeZoomHandler(handler){
        this.#map.off('zoom', handler)
    }

    setLayerClickHandler(name,handler){
        this.#map.on("click", name, handler);
    }

    removeLayerClickHandler(name,handler){
        this.#map.off("click", name, handler);
    }

    setMouseEnterHandler(name,handler){
        this.#map.on("mouseenter", name, handler);
    }

    removeMouseEnterHandler(name,handler){
        this.#map.off("mouseenter", name, handler);
    }

    setMouseLeaveHandler(name,handler){
        this.#map.on("mouseleave", name, handler);
    }

    removeMouseLeaveHandler(name,handler){
        this.#map.off("mouseleave", name, handler);
    }

    setMouseMoveHandler(name,handler){
        this.#map.on("mousemove", name, handler);
    }

    removeMouseMoveHandler(name,handler){
        this.#map.off("mousemove", name, handler);
    }
    
    removeSource(name){
        console.log("remove source: "+name)
        if(this.#map.getSource(name))
            this.#map.removeSource(name)
    }

    removeLayer(name){
        console.log("remove layer: "+name)
        if(this.#map.getLayer(name))
            this.#map.removeLayer(name)
    }

    removeMap(){
        this.#map.remove();
    }


}

export class Popup{
    popup=null

    constructor(){
        this.popup = new mapboxgl.Popup({
            closeButton: false,
            closeOnClick: false,
            className: "mypopup",
          }).trackPointer();


        return this.popup
    }

    isOpen(){
        return this.popup.isOpen();
    }

    setHtml(html){
        this.popup.setHTML(html);
    }

    remove(){
        this.popup.remove();
    }
}

export class Control{
    control=null

    constructor(){
        const modes = MapboxDraw.modes;
        modes.draw_rectangle = DrawRectangle;

        this.control = new MapboxDraw({
            modes: modes,
            displayControlsDefault: false,
            // Select which mapbox-gl-draw control buttons to add to the map.
            controls: {
            polygon: true,
            trash: true
            }
        });

    }

 
}
