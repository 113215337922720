import React from 'react'
import {Table} from '@zenithmaps/zenith_client'
import MapeditorModel from './MapeditorModel.json'
import {Link} from 'react-router-dom'
import { useNavigate  } from "react-router-dom";

export default function MapeditorPage({map,mapLoaded}) {

    let navigate = useNavigate ();
    // const [recordId,setRecordId]=useState(-1)
    // const [formMethod, setFormMethod]=useState()
    // const [tableCount, setTableCount]=useState(0)

    const action_view=(new_Id)=>{
       navigate("/mapeditor_form/"+new_Id+"/view");
    }
    const action_edit=function(new_Id){
      navigate("/mapeditor_form/"+new_Id+"/edit");
    }
    function action_delete(new_Id){
        navigate("/mapeditor_form/"+new_Id+"/delete");
    }

    // function action_create(){
    //     setFormMethod("create")
	// 	setRecordId(-1)
    // }

    //const actions ={view:action_view, edit:action_edit, delete:action_delete}
    const actions=[
        {
            name: "view",
            onClickFunc: action_view,
            param: "id",
            buttonIcon: <i className="far fa-eye fa-sm green-text"></i>
        },
        {
            name: "edit",
            onClickFunc: action_edit,
            param: "id",
            buttonIcon: <i className="fas fa-pencil-alt fa-sm green-text"></i>
        },
        {
            name: "delete",
            onClickFunc: action_delete,
            param: "id",
            buttonIcon: <i className="far fa-trash-alt fa-sm green-text"></i>
        }

    ]
	return (
            <>
                <div className="container p-3">
                    <div className="mx-1">
                        <p className="text-center green-text h5">Mapeditor</p>
                    </div>
                    {/* <div className="col-md-12  text-left position-relative">
                        <MapeditorForm  setTableCount={setTableCount} tableCount={tableCount} recordId={recordId} method={formMethod}/>  
                    </div>  */}
                    <div className=" d-flex justify-content-end">
                        <Link to="/mapeditor_form/-1/create" className="btn btn-primary"><i className="fas fa-pencil-alt mt-0"></i></Link>
                        {/* <button id="create_rec" type="button" className="btn btn-outline-green btn-rounded px-2 waves-effect waves-light" onClick={action_create} title="Create">
                            <i className="fas fa-pencil-alt mt-0"></i>
                        </button> */}
                    </div> 
                    <div className="col-md-12  text-left position-relative">
                        <Table model={MapeditorModel}  table_name="mapeditor" table_url={process.env.REACT_APP_MAPSERVER+"/api/mapfiles"} actions={actions} paging search/>  
                    </div>  
                </div> 
            </>
        )
  }
