import * as turf from '@turf/turf'


export default function scaleGeomToFit(geom,width,height){

    var bbox = turf.bbox(geom);
    let geomWidth=bbox[2]-bbox[0];
    let geomHeight=bbox[3]-bbox[1];
    let scaleFactor;
    let toFitRatio

    if(width>height){
        toFitRatio=width/height;
        scaleFactor=(geomHeight*toFitRatio)/geomWidth;
    }
    else{
        toFitRatio=height/width;
        scaleFactor=(geomWidth*toFitRatio)/geomHeight;
    }

    geom=turf.transformScale(geom,scaleFactor,{origin:'center'})

    return geom

}