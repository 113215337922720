import Modal from 'react-modal';
import React, {useMemo} from 'react';


export function StyleModal({defColor, children, setModaleState, modaleState}){

    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        },
      };

    function closeModal() {
        setModaleState(false);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.

    }
    
    useMemo(()=>{
      Modal.setAppElement('body');
    },[])

    return (
        <div>
          <Modal
            isOpen={modaleState}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
          >
            {children}
            <button onClick={closeModal}>close</button>
          </Modal>
        </div>
      );
}