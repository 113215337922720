
import React, {useState} from 'react'
import {useStore} from 'common/utils/store.js'
import { ColorPicker } from '@zenithmaps/zenith_client';
import { StyleModal } from './StyleModal';
import reactCSS from 'reactcss'

export default function Style({style, styleIndex, classIndex, layerIndex, saveMap}){

    const setMapJson = useStore((state) => state.setMapJson);
    let mapJson={...useStore((state) => state.mapJson)}
    const [modaleState,setModaleState] = useState(false);

    const  handleChange=(name,value)=>{
        mapJson.layers[layerIndex].classes[classIndex].styles[styleIndex][name]=value
        setMapJson(mapJson)
        saveMap()
    }

    const styles = reactCSS({
        'default': {
            color: {
                width: '36px',
                height: '14px',
                borderRadius: '2px',
                background: `${style.color}`,
                "outline-style": 'solid',
                "outline-color": `${style.outlinecolor}`,
                "outline-width": `${style.width}px`
            },
            swatch: {
                padding: '5px',
                background: '#fff',
                borderRadius: '1px',
                boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                display: 'inline-block',
                cursor: 'pointer',
            }
        },
    });


    return(
        <div>
            <div style={ styles.swatch } onClick={()=>{setModaleState(true)} }>
                <div style={ styles.color } />
            </div>
            <StyleModal setModaleState={setModaleState} modaleState={modaleState} >
                <span>Color</span>
                <ColorPicker defColor={style.color} name="color" onChange={handleChange}/>
                <span>Outline color</span>
                <ColorPicker defColor={style.outlinecolor} name="outlinecolor" onChange={handleChange}/>
            </StyleModal>
            {/* <ColorPicker defColor={style.color} name="color" onChange={handleChange}/> */}
            {/* <MapInputCtrl label="OPACITY" name="OPACITY" type="text" value={style.OPACITY ? style.OPACITY : '' } onChange={handleChange} className="ps-3"/>
            <MapInputCtrl label="COLOR" name="COLOR" type="text" value={style.COLOR ? style.COLOR : '' } onChange={handleChange} className="ps-3"/>
            <MapInputCtrl label="SIZE" name="SIZE" type="text" value={style.SIZE ? style.SIZE : '' } onChange={handleChange} className="ps-3"/>
            <MapInputCtrl label="SYMBOL" name="SYMBOL" type="text" value={style.SYMBOL ? style.SYMBOL : '' } onChange={handleChange} className="ps-3"/> */}
        </div>
    )
}

