import React, {useState, useEffect, useRef, useReducer, useCallback} from 'react'
import axios from 'axios'
import {InputCtrl} from  '@zenithmaps/zenith_client'
import {ButtonCtrl} from  '@zenithmaps/zenith_client'
import {Translate} from  '@zenithmaps/zenith_client'
import {Spinner} from  '@zenithmaps/zenith_client'
import { Control } from 'mapbox/MapWrapper'
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'

import * as turf from '@turf/turf'

import { geojsonToWKT } from "@terraformer/wkt"
import {exportMapserverMap} from 'mapbox/exportMapserverMap'
import scaleGeomToFit from 'mapbox/scaleGeomToFit'

function HartiForm({setTableCount,tableCount, map, recordId, method, onSelected}){
	
    const [fields, dispatch] = useReducer(reducer, {});
    const mapRef=map.current;
    const draw=useRef(null)

    function reducer(state, action) {

        console.log(state)

        if(Object.keys(action).length!==1){  return action }

        if(action.the_geom){
            let geom=action.the_geom

            geom=scaleGeomToFit(geom,state["width"],state["height"])
            var bbox = turf.bbox(turf.toMercator(geom));
    
            geom=geojsonToWKT(turf.toMercator(geom))
            let newState={...state}
            newState.the_geom=geom
            newState.extent=bbox.toString()
            return newState

        }
        else{
            let newState={...state}
            for (let key in action) {
                newState[key]=action[key]
            }    
            return newState
        }

     
    }
	const [errors,setErrors]=useState({})
	const [message,setMessage]=useState('')
	const [messageClass,setMessageClass]=useState('')
	const [isLoading,setIsLoading]=useState(false)

    let apiCall = axios.post
    let btnLabel=''
	
	switch(method) {
        case "view":
            apiCall = axios.get
            btnLabel="Close"
          break;
        case "edit":
            apiCall = axios.put
            btnLabel="Update"
          break;
        case "delete":
            apiCall = axios.delete
            btnLabel="Delete"
        break; 
        case "create":
            apiCall = axios.post
            btnLabel="Create"
        break; 
        default:
            apiCall = axios.post
            btnLabel="Create"
        break;
    }
	
	useEffect(()=>{		
		if(recordId && recordId>-1){
				setIsLoading(true)
				axios.get('/harti/' + recordId).then(response => {
																		//setFields(response.data)
                                                                        dispatch(response.data.output)
                                                                        console.log(response.data.output)
																		setIsLoading(false)
																	})
			}
	},[recordId])
				
    const handleChange = (field,value)=>{
        let fld={}
        fld[field]=value
        dispatch(fld)
	}

    const updateArea=useCallback((e)=>{
        const data = draw.current.control.getAll();
        if (data.features.length > 0) {

            handleChange('the_geom', data.features[0].geometry)
        } else {
            if (e.type !== 'draw.delete')
            alert('Click the map to draw a polygon.');
        }

    },[])
    
    useEffect(()=>{		
        
        draw.current = new Control();
        mapRef.addControl(draw.current.control,updateArea);
        draw.current.control.changeMode('draw_rectangle');

        return ()=>{
            mapRef.removeControl(draw.current.control)
        }
	},[mapRef, updateArea])
    
   
    // function updateArea(e) {
           
     
    // }

    function exportMap(){
        exportMapserverMap(fields["mapfile"],fields["width"],fields["height"],fields["resolution"],fields["extent"])
    }

	const handleSubmit = (event) => {
          event.preventDefault();  
  
          const data = new FormData(event.currentTarget)
          const values = Object.fromEntries(data.entries())

		  setMessage("Loading...")
		  setIsLoading(true)
		  
          apiCall('/harti/'+recordId,values)
			.then( response => {
                setMessage(response.data.message)
                setMessageClass('alert alert-success')
                setIsLoading(false)           
              //  setFields({})              
                let count=tableCount + 1
                setTableCount(count)
                map.current.refreshAllLayers()
            })
            .catch( error => {
                setMessageClass('alert alert-danger')
                setIsLoading(false)
                if(error.response.data.errors){
                    setMessage(<Translate>Please correct the errors</Translate>)
                    setErrors(error.response.data.errors)
                }
                else{
                    setMessage("An error ocurred!")
                    setErrors({})
                } 
            })
	}

	return(
		<React.Fragment>
                <Spinner isLoading={isLoading}/>   
                <form onSubmit={handleSubmit} className="py-1" >
                    <div className="row">    
                        <div className="col-md-3  text-left ">
                            <InputCtrl
                                    label="The_geom"
                                    name="the_geom"
                                    type="text"
                                    value={fields.the_geom}
                                    onChange={handleChange}
                                    placeholder=""
                                    error={errors.the_geom}
                                    required
                                    className=""
                                />
                        </div>    
                        <div className="col-md-3  text-left ">
                            <InputCtrl
                                    label="Den"
                                    name="den"
                                    type="text"
                                    value={fields.den}
                                    onChange={handleChange}
                                    placeholder=""
                                    error={errors.den}
                                    required
                                    className=""
                                />
                        </div>    
                        <div className="col-md-3  text-left ">
                            <InputCtrl
                                    label="Mapfile"
                                    name="mapfile"
                                    type="text"
                                    value={fields.mapfile}
                                    onChange={handleChange}
                                    placeholder=""
                                    error={errors.mapfile}
                                    required
                                    className=""
                                />
                        </div>    
                        <div className="col-md-3  text-left ">
                            <InputCtrl
                                    label="Extent"
                                    name="extent"
                                    type="text"
                                    value={fields.extent}
                                    onChange={handleChange}
                                    placeholder=""
                                    error={errors.extent}
                                    required
                                    className=""
                                />
                        </div>    
                        <div className="col-md-3  text-left ">
                            <InputCtrl
                                    label="Resolution"
                                    name="resolution"
                                    type="text"
                                    value={fields.resolution}
                                    onChange={handleChange}
                                    placeholder=""
                                    error={errors.resolution}
                                    required
                                    className=""
                                />
                        </div>    
                        <div className="col-md-3  text-left ">
                            <InputCtrl
                                    label="Width"
                                    name="width"
                                    type="text"
                                    value={fields.width}
                                    onChange={handleChange}
                                    placeholder=""
                                    error={errors.width}
                                    required
                                    className=""
                                />
                        </div>    
                        <div className="col-md-3  text-left ">
                            <InputCtrl
                                    label="Height"
                                    name="height"
                                    type="text"
                                    value={fields.height}
                                    onChange={handleChange}
                                    placeholder=""
                                    error={errors.height}
                                    required
                                    className=""
                                />
                        </div>    
                        <div className="col-md-3  text-left ">
                            <InputCtrl
                                    label="Scale"
                                    name="scale"
                                    type="text"
                                    value={fields.scale}
                                    onChange={handleChange}
                                    placeholder=""
                                    error={errors.scale}
                                    required
                                    className=""
                                />
                        </div>    
                        <div className="col-md-3  text-left ">
                            <InputCtrl
                                    label="Zmin"
                                    name="zmin"
                                    type="text"
                                    value={fields.zmin}
                                    onChange={handleChange}
                                    placeholder=""
                                    error={errors.zmin}
                                    required
                                    className=""
                                />
                        </div>    
                        <div className="col-md-3  text-left ">
                            <InputCtrl
                                    label="Zmax"
                                    name="zmax"
                                    type="text"
                                    value={fields.zmax}
                                    onChange={handleChange}
                                    placeholder=""
                                    error={errors.zmax}
                                    required
                                    className=""
                                />
                        </div>    
                        <div className="col-md-3  text-left ">
                            <InputCtrl
                                    label="Tilepath"
                                    name="tilepath"
                                    type="text"
                                    value={fields.tilepath}
                                    onChange={handleChange}
                                    placeholder=""
                                    error={errors.tilepath}
                                    required
                                    className=""
                                />
                        </div>    
                        <div className="col-md-3  text-left ">
                            <InputCtrl
                                    label="Pblc"
                                    name="pblc"
                                    type="text"
                                    value={fields.pblc}
                                    onChange={handleChange}
                                    placeholder=""
                                    error={errors.pblc}
                                    required
                                    className=""
                                />
                        </div>
					    <div className="col-md-2 p-2 d-flex">
                            <label  className="px-2">Draw point</label>
                        </div>
                        <div className="col-md-1  px-5 d-flex">
								 <button id="start_draw" type="button" className="align-middle btn btn-outline-green btn-rounded px-2 waves-effect waves-light" onClick={()=>exportMap()} title="Export">
                                    <i className="fa-solid fa-file-export"></i>
								</button>
                        </div> 
                    </div>
                    <div className="row">
                        <div className="col-md-3 py-1 mb-1 text-left ">   
                                <ButtonCtrl
                                    type="submit"
                                    label=<Translate>{btnLabel}</Translate>
                                    className="btn btn-primary btn-rounded px-4 text-white nav-link active z-depth-0"
                                    handleClick=""
                                />
                        </div> 
                        <div className="col-md-9 pb-1 text-left "> 
                            <div className={messageClass}>{message}
                            </div>
                        </div> 
                    </div>    
                </form>
            </React.Fragment>
        )	
	
}
export default HartiForm