// import logo from './logo.svg';
// Bootstrap CSS
//import "bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
//import "bootstrap/dist/js/bootstrap.bundle.min";
import "./App.css";
import './assets/fontawesome-free-6.2.0-web/css/all.css'
import { useState, useEffect, useRef, useMemo } from "react";
import axios from "axios";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import AxiosInit from "./common/axios/AxiosInit";

import { LoginForm , RegisterForm, ForgotForm, Header, MenuItem,UserMenu, LangMenu, LangContext, SetObject, GetObject  } from "@zenithmaps/zenith_client";

import SidePanel from "common/pannels/sidepanel";
import MapeditorForm from 'mapeditor/MapeditorForm'
import MapeditorPage from 'mapeditor/MapeditorPage'
import Map from "Map";
import HartiPage from "mapexport/HartiPage";

import { QueryClient, QueryClientProvider } from "react-query";
const queryClient = new QueryClient();

function App() {
  const [lang, setLang] = useState("en");
  const [user, setUser] = useState(GetObject("user") ? GetObject("user") : "guest");
  const [jwt, setJwt] = useState(GetObject("jwt") ? GetObject("jwt") : "");
  const [mapLoaded, setMapLoaded] = useState(false);

  const map = useRef(null);
  const axiosRef=useRef();

  console.log("In App");

  useMemo(()=>{
    axiosRef.current=new AxiosInit(jwt,resetUser)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]) 


  useEffect(() => {
    SetObject("user", user);
  }, [user]);

  useEffect(() => {
    SetObject("jwt", jwt);
    axios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;
  }, [jwt]);

  function resetUser(user, jwt) {
    setUser(user);
    setJwt(jwt);
  }

  useEffect(() => {
    return () => {
      axiosRef.current.destructor()
    };
  }, []);

  return (
      <Router>
        <QueryClientProvider client={queryClient}>
          <LangContext.Provider value={lang}>
            <main className='w-full h-full'>
              <SidePanel>
                <Header>
                  <UserMenu user={user} resetUser={resetUser} />
                  <MenuItem  link='/mapeditor' text='Map editor' />
                  <MenuItem  link='/mapexport' text='Map export' />
                </Header>   
                  <Routes>
                    <Route exact path='/' element={<LoginForm resetUser={resetUser} />} />
                    <Route exact path='/login' element={<LoginForm resetUser={resetUser} />} />
                    <Route exact path='/register' element={<RegisterForm />} />
                    <Route exact path='/forgot' element={<ForgotForm />} />
                    <Route exact path='/mapeditor' element={<MapeditorPage />} />
                    <Route exact path='/mapeditor_form/:id/:method' element={<MapeditorForm map={map} mapLoaded={mapLoaded}/>} />
                    <Route exact path='/mapexport'   element={<HartiPage map={map} mapLoaded={mapLoaded} />} />
                  </Routes>
              </SidePanel>  
              <Map map={map} setMapLoaded={setMapLoaded}></Map>
              {/* <div id="modalElement"></div> */}
            </main>
          </LangContext.Provider>
        </QueryClientProvider>
      </Router>
  );
}

export default App;
